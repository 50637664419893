<!-- 配电图 -->
<template>
  <div id="distribution">
    <!-- <div class="main">
      <div class="query-form">
        <el-form class="form" ref="form" :inline="true" :model="formData">
          <el-form-item label="变配电站:">
            <el-input placeholder="请输入内容" suffix-icon="el-icon-search">
            </el-input>
          </el-form-item>
        </el-form>
        <div class="update-time" @click="dialogVisible = true">
          <span>更新时间</span>
          <p>2023-12-12</p>
        </div>
      </div>
    </div> -->
    <el-dialog @close="handleClose" :title="titleShow == 0 ? '变配电站' : '选择负责人'" :visible.sync="dialogVisible">
      <div class="dialog-content">
        <div class="content-left" style=" width: 313px">
          <ul class="tabs" v-if="titleShow == 0">
            <li v-for="(item, index) in tabsData" :key="index" :class="select === index ? 'select' : ''"
              @click="switchTabs(index)">
              {{ item }}
            </li>
          </ul>
          <div class="search">
            <el-input placeholder="请输入内容"> </el-input>
          </div>
          <ul class="select">
            <li v-for="(item, index) in selectData" :key="index">
              <input type="checkbox" /><span>{{ item }}</span>
            </li>
          </ul>
        </div>
        <div class="content-right">
          <div class="query">
            <el-input placeholder="请输入检索内容">
            </el-input>
            <el-button type="primary">全部</el-button>
          </div>
          <el-table v-if="titleShow == 0" class="table" :data="tableData">
            <el-table-column align="center" prop="name" label="变配电站">
            </el-table-column>
            <el-table-column align="center" prop="zero" label="组织机构">
            </el-table-column>
            <el-table-column align="center" prop="one" label="区域">
            </el-table-column>
            <el-table-column align="center" prop="two" label="用户地址">
            </el-table-column>
          </el-table>
          <el-table v-if="titleShow !== 0" class="table" :data="tableDataName">
            <el-table-column width="50">
              <template slot-scope="scope">
                <el-radio class="tableRadio" v-model="selectedRow" :label="scope.row">{{ }}</el-radio>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="name" label="用户名">
            </el-table-column>
            <el-table-column align="center" prop="name1" label="姓名">
            </el-table-column>

          </el-table>

          <div class="pagination">
            <button class="home-page">首页</button>
            <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
              layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total"
              @size-change="changeSize" @current-change="changeCurrent">
            </el-pagination>
            <button class="tail-page">尾页</button>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" :class="titleShow == 0 ? 'footerTitleShow' : ''">
        <div class="footerL" v-if="titleShow !== 0">
          选中项：
          <span class="selectBottomBtn"> 王五 <i class="el-icon-close"></i> </span>
        </div>
        <div class="">

          <el-button>取消</el-button>
          <el-button type="primary">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    titleShow: {
      type: Number,
      default: 0
    }
  },
  watch: {
    value(newValue) {
      this.dialogVisible = newValue;
    },

  },

  data() {
    return {
      formData: {
        name: "",
      },
      selectData: [
        "全部产权单位",
        "北京鸿运承物业",
        "第一客运分公司",
        "第二客运分公司",
        "第三客运分公司",
        "第四客运分公司",
        "第五客运分公司",
        "第六客运分公司",
        "第七客运分公司",
        "第八客运分公司",
        "第九客运分公司",
        "电车客运分公司",
        "保修分公司",
        "燃料供应分公司",
        "产权单位",
      ],
      tabsData: ["组织结构", "区域"],
      select: 0,
      dialogVisible: false,
      tableData: [
        {
          name: "变配电站001",
          zero: "组织结构名称",
          one: "区域位置",
          two: "用户地址位置",
        },
        {
          name: "变配电站002",
          zero: "组织结构名称",
          one: "区域位置",
          two: "用户地址位置",
        },
        {
          name: "变配电站003",
          zero: "组织结构名称",
          one: "区域位置",
          two: "用户地址位置",
        },

      ],
      selectedRow: null, // 选中的行数据
      tableDataName: [
        {
          name: '用户名',
          name1: '张三'
        },
        {
          name: '用户名',
          name1: '李四'
        },
      ],

      requestParam: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
    };
  },

  mounted() { },

  methods: {
    //点击关闭弹窗
    handleClose() {
      this.$emit('MYinput', this.dialogVisible);

    },

    changeSize(pageSize) {
      this.requestParam.pageNum = pageSize;
      // this.queryOperation();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      // this.queryOperation();
    },
    switchTabs(index) {
      this.select = index
    },
  },
};
</script>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

#distribution {
  /* width: 100%;
  min-width: 1621px;
  height: 903px;
  background: url(../../../assets/images/rightBG.png);
  background-repeat: no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  padding: 21px;
  box-sizing: border-box; */
}

.query-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form :deep().el-form-item__label {
  font-family: Microsoft YaHei;
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
}

.form:deep() input {
  width: 202px;
  height: 35px;
  border: 1px solid #09295b;
  background-color: #041c4c;
  color: #aed6ff;
  font-size: 13px;
}

.form:deep().el-icon-search {
  color: #1f49b5;
}

.update-time {
  display: flex;
  align-items: center;
  color: #aed6ff;
  font-size: 12px;
}

.update-time span::after {
  content: ":";
  margin: 0 3px;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

.dialog-content {
  display: flex;
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #aed6ff;
}

.el-dialog__footer {
  /* display: flex;
  align-content: ; */
}

.dialog-footer button {
  width: 106px;
  height: 36px;
  background: #2142a1;
  border: 0;
  color: #ffffff;
  font-size: 13px;
}

/* tabs */
.tabs {
  display: flex;
}

.tabs li {
  width: 151px;
  height: 47px;
  border-radius: 3px;
  background-color: #09244f;
  font-family: Microsoft YaHei;
  color: #c9d4f1;
  font-size: 14px;
  text-align: center;
  line-height: 47px;
}

.content-left {
  width: 303px !important;
  height: 610px;
  border-radius: 3px;
  border: 1px solid #06224e;
  background-color: #071c3f;
  box-sizing: border-box;
}

.tabs .select {
  background-color: #1c3d7d;
  color: #ffc74a;
}

.search :deep() input {
  width: 262px;
  height: 35px;
  border: 1px solid #09295b;
  background-color: #09244d;
  color: #aed6ff;
  font-size: 13px;
  margin: 18px 0 16px 20px;
}

.select {
  width: 262px;
  height: 474px;
  padding: 2px 2px 2px 2px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  margin-left: 20px;
}

.select li {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}

.select input {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  background-color: #176ad2;
}

.select span {
  color: #aed6ff;
  font-size: 14px;
}

.content-right {
  width: 837px;
  height: 610px;
  padding: 16px 0 0 21px;
  border-radius: 3px;
  border: 1px solid #06224e;
  background-color: #071c3f;
  box-sizing: border-box;
  margin-left: 15px;
}

.query {
  display: flex;
  margin-bottom: 14px;
}

.query :deep() .el-input {
  width: 347px;
}

.query :deep() input {
  width: 347px;
  height: 35px;
  border-radius: 2px;
  border: 1px solid #09295b;
  background-color: #09244d;
  font-size: 13px;
  color: #aed6ff;

}

.query :deep() button {
  width: 68px;
  height: 36px;
  border-radius: 3px;
  border: 1px solid rgba(121, 121, 121, 0);
  background-color: #176ad2;
  box-sizing: border-box;
  color: #fdfdfd;
  text-align: center;
  line-height: 36px;
  padding: 0;
}

/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover>td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row>td.el-table__cell {
  background-color: #081f42 !important;
}

/* 分页 */
.pagination {
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination :deep() .el-pagination {
  padding-left: 0;
}

.home-page,
.tail-page {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #92b5da;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
}

.home-page:hover,
.tail-page:hover {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.home-page:checked,
.tail-page:checked {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  width: 60px;
  height: 30px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  margin-right: 5px;
  line-height: 30px;
  padding: 0;
  text-align: center;
}

.el-pagination>>>.el-pager li {
  width: 35px;
  height: 30px;
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.pagination :deep().el-select .el-input__inner {
  height: 30px;
  color: #92b5da;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
}

.pagination :deep() .el-pagination__sizes {
  margin-right: 0;
}


.selectBottomBtn {
  width: 75px;
  height: 34px;
  padding: 2px 2px 2px 2px;
  border-radius: 2px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-size: 14px;
  color: #176ad2;
}

.footerTitleShow {
  justify-content: center;
}
</style>
